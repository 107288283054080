import PropTypes from "prop-types";

// Partials
import Pricebox from "/views/partials/pricebox";
import { useNavigator } from "pstv-commerce-tools/utilities/navigator";

const CheckoutSummary = ({ summary, deliveryLimits }) => {
    const { activeRoute } = useNavigator();

    // Helper function to format the price with 2 decimal places
    const formatPrice = (price) => {
        return Number(price).toFixed(2);
    };

    if (summary?.price?.original > 0) {
        return (
            <div className="aside-summary">
                <div className="summary-item">
                    <strong>Ürünler Toplamı</strong>
                    <Pricebox
                        className="item-price"
                        price={formatPrice(summary.price.original)}
                    />
                </div>

                {summary.services.map((service, nth) => {
                    if (
                        service.service_code !== "KARGO" &&
                        service.service_code !== "delivery"
                    ) {
                        return (
                            <div className="summary-item" key={nth}>
                                <strong>{service.name}</strong>
                                <Pricebox
                                    className="item-price"
                                    price={formatPrice(service.price.original)}
                                />
                            </div>
                        );
                    } else {
                        let deliveryFee;

                        if (activeRoute.path === "/siparis") {
                            const isCorporate = summary?.invoice_type === 2;

                            if (isCorporate) {
                                // ! Kurumsal teslimat ücreti kontrolü
                                deliveryFee =
                                    Number(summary.grand_total.original) <
                                    Number(
                                        deliveryLimits
                                            ?.corporate_home_delivery_free_limit
                                            ?.original
                                    )
                                        ? Number(
                                              deliveryLimits
                                                  ?.corporate_home_delivery_fee
                                                  ?.original
                                          ).toFixed(2)
                                        : "0.00";
                            } else {
                                // ! Bireysel teslimat ücreti kontrolü
                                deliveryFee =
                                    Number(summary.grand_total.original) <
                                    Number(
                                        deliveryLimits?.home_delivery_free_limit
                                            ?.original
                                    )
                                        ? Number(
                                              deliveryLimits?.home_delivery_fee
                                                  ?.original
                                          ).toFixed(2)
                                        : "0.00";
                            }
                        } else {
                            // ! Eğer '/siparis' path'i değilse, servis fiyatını kullan
                            deliveryFee = formatPrice(service.price.original);
                        }

                        return (
                            <div className="summary-item" key={nth}>
                                <strong>{service.name}</strong>
                                <Pricebox
                                    className="item-price"
                                    price={deliveryFee}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        );
    } else {
        return false;
    }
};

CheckoutSummary.propTypes = {
    summary: PropTypes.object,
    deliveryLimits: PropTypes.object,
};

export default CheckoutSummary;
