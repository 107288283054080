import "/assets/styles/pages/checkout.scss";
import { useMemo, useState } from "react";

// Functions
import formatDate from "pstv-commerce-tools/functions/format-date";

// Context
import { useCheckout, paymentMethods } from "/utilities/checkout";
import { useModals } from "pstv-commerce-tools/utilities/modals";
import { useNavigator } from "pstv-commerce-tools/utilities/navigator";

// Partials
import { Form, Input, FormFeedback } from "/views/partials/forms";
import CheckoutSummary from "/views/partials/checkout-summary";
import CardTypeIcon from "/views/partials/card-type-icon";
import Loader from "/views/partials/loader";
import Btn from "/views/partials/btn";
import Pricebox from "/views/partials/pricebox";
import Icon from "pstv-commerce-tools/partials/icon";
import Img from "pstv-commerce-tools/partials/img";

// Static Assets
import image_payment_masterpass from "/assets/images/payment/masterpass.svg";
import classNames from "classnames";

const Checkout = () => {
    const { redirect } = useNavigator();

    const checkInitialPaymentError = () => {
        let returnStatus = false;

        if (window.location.search === "?error3dResponse=true") {
            returnStatus = true;

            setTimeout(() => {
                redirect("checkout", undefined, { replace: true });
            }, 250);
        }

        return returnStatus
            ? "Ödeme alınırken bir hata ile karşılaşıldı, lütfen tekrar deneyin."
            : false;
    };

    const {
        loading,
        sale,
        busy,
        submitting,
        address,
        /*invoiceAddress */ paymentInfo,
        submitCheckout,
    } = useCheckout();
    const { openModal } = useModals();
    const [feedbackData, setFeedbackData] = useState(
        checkInitialPaymentError()
    );

    const [isAgreementConfirmed, setAgreementConfirm] = useState(false);

    const submit = async (formData) => {
        setFeedbackData(false);

        if (paymentInfo.isDebit) {
            openModal("message", {
                message:
                    "Online ödemede kredi kartı ile ödeme yapılmaktadır. Kredi kartınız ile online ödeme ya da banka kartınızla kapıda ödeme yapabilirsiniz.",
            });
        } else if (paymentInfo.enpara) {
            openModal("message", {
                message:
                    "Online ödemelerde yalnızca provizyon desteği olan kredi kartları ile ödeme alabiliyoruz. Lütfen farklı bir kart ile deneyiniz.",
            });
        } else if (paymentInfo.isSupportedType === false) {
            setFeedbackData(
                "Seçtiğiniz kart Metro Türkiye tarafından desteklenmediği için işleminizie devam edemiyoruz. Lütfen başka bir kart ile deneyin."
            );
        }

        try {
            await submitCheckout({
                non_contact: formData.non_contact === "1",
                no_bell: formData.no_bell === "1",
            });
        } catch (error) {
            setFeedbackData(error);
        }
    };

    return (
        <>
            <header className="checkout-header">
                <div className="wrapper">
                    <h1 className="header-title">Siparişi Tamamla</h1>
                </div>
            </header>
            <Form
                className="checkout-wrap wrapper loader-container"
                onSubmit={submit}
            >
                <Loader
                    solid
                    loading={loading}
                    message="Siparişiniz Getiriliyor..."
                />
                {sale && (
                    <>
                        <section className="checkout-wrap-main">
                            <FormFeedback
                                className="checkout-feedback"
                                data={feedbackData}
                            />

                            <div
                                className={classNames(
                                    "main-section",
                                    "checkout-address"
                                )}
                            >
                                <div className="address-subsection">
                                    <strong className="section-title">
                                        Teslimat Adresi
                                    </strong>
                                    <div className="address-content">
                                        <strong className="content-title">
                                            {address.name}
                                        </strong>
                                        <div className="content-info">
                                            <p className="info-field">
                                                <strong>Adres:</strong>{" "}
                                                <span>{address.address}</span>
                                            </p>
                                            <p className="info-field">
                                                <strong>Bina No:</strong>{" "}
                                                <span>
                                                    {address.building_number}
                                                </span>
                                            </p>
                                            <p className="info-field">
                                                <strong>Kat No:</strong>{" "}
                                                <span>{address.floor}</span>
                                            </p>
                                            <p className="info-field">
                                                <strong>Daire No:</strong>{" "}
                                                <span>
                                                    {address.door_number}
                                                </span>
                                            </p>
                                            <p className="info-field">
                                                <strong>Daire No:</strong>{" "}
                                                <span>
                                                    {address.door_number}
                                                </span>
                                            </p>
                                            <p className="info-field">
                                                <strong>Adres Tarifi:</strong>{" "}
                                                <span>
                                                    {address.for_directions}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="address-subsection">
                                    <strong className="section-title">
                                        Fatura Adresi
                                    </strong>
                                    <div className="address-content">
                                        {sale?.invoice_type === 1 && (
                                            <>
                                                <div className="corporate-info-field">
                                                    Bireysel Fatura
                                                </div>
                                                <strong className="content-title">
                                                    {address.name}
                                                </strong>
                                                <div className="content-info">
                                                    <p className="info-field">
                                                        <strong>Adres:</strong>{" "}
                                                        <span>
                                                            {address.address}
                                                        </span>
                                                    </p>
                                                    <p className="info-field">
                                                        <strong>
                                                            Bina No:
                                                        </strong>{" "}
                                                        <span>
                                                            {
                                                                address.building_number
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="info-field">
                                                        <strong>Kat No:</strong>{" "}
                                                        <span>
                                                            {address.floor}
                                                        </span>
                                                    </p>
                                                    <p className="info-field">
                                                        <strong>
                                                            Daire No:
                                                        </strong>{" "}
                                                        <span>
                                                            {
                                                                address.door_number
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="info-field">
                                                        <strong>
                                                            Daire No:
                                                        </strong>{" "}
                                                        <span>
                                                            {
                                                                address.door_number
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="info-field">
                                                        <strong>
                                                            Adres Tarifi:
                                                        </strong>{" "}
                                                        <span>
                                                            {
                                                                address.for_directions
                                                            }
                                                        </span>
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        {sale?.invoice_type === 2 && (
                                            <div
                                                className="content-info"
                                                style={{ marginBottom: "1rem" }}
                                            >
                                                <div className="corporate-info-field">
                                                    Kurumsal Fatura
                                                </div>
                                                <strong className="content-title">
                                                    {
                                                        sale?.customer_invoice
                                                            ?.invoice_title
                                                    }
                                                </strong>

                                                <div className="content-info">
                                                    <p className="info-field">
                                                        <strong>İsim:</strong>{" "}
                                                        <span>
                                                            {
                                                                sale
                                                                    ?.customer_invoice
                                                                    ?.name
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="info-field">
                                                        <strong>Adres:</strong>{" "}
                                                        <span>
                                                            {
                                                                sale
                                                                    ?.customer_invoice
                                                                    ?.address
                                                            }
                                                        </span>
                                                    </p>

                                                    <p className="info-field">
                                                        <strong>
                                                            Vergi Dairesi:
                                                        </strong>{" "}
                                                        <span>
                                                            {
                                                                sale
                                                                    ?.customer_invoice
                                                                    ?.tax_office
                                                            }
                                                        </span>
                                                    </p>
                                                    {sale?.customer_invoice
                                                        ?.tax_number && (
                                                        <p className="info-field">
                                                            <strong>
                                                                Vergi No:
                                                            </strong>{" "}
                                                            <span>
                                                                {
                                                                    sale
                                                                        ?.customer_invoice
                                                                        ?.tax_number
                                                                }
                                                            </span>
                                                        </p>
                                                    )}
                                                    {sale?.customer_invoice
                                                        ?.idno && (
                                                        <p className="info-field">
                                                            <strong>
                                                                TC Kimlik No:
                                                            </strong>{" "}
                                                            <span>
                                                                {
                                                                    sale
                                                                        ?.customer_invoice
                                                                        ?.idno
                                                                }
                                                            </span>
                                                        </p>
                                                    )}
                                                    {sale?.customer_invoice
                                                        ?.mobile_number && (
                                                        <p className="info-field">
                                                            <strong>
                                                                Telefon:
                                                            </strong>{" "}
                                                            <span>
                                                                {
                                                                    sale
                                                                        ?.customer_invoice
                                                                        ?.mobile_number
                                                                }
                                                            </span>
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <DeliverySlots />

                            <Payment />

                            <CheckoutOptions />
                        </section>
                        <aside className="checkout-wrap-aside loader-container">
                            <div className="aside-content">
                                <div className="aside-total">
                                    <strong className="total-title">
                                        Toplam Tutar <span>(KDV Dahil)</span>
                                    </strong>

                                    <Pricebox
                                        className="total-price"
                                        big
                                        price={sale.summary.grand_total}
                                    />
                                </div>

                                <Input
                                    type="checkbox"
                                    uncheckedValue={false}
                                    name="agreement"
                                    onChange={setAgreementConfirm}
                                    validation={{
                                        required:
                                            "Siparişi tamamlamak için sözleşmeleri onaylamalısınız.",
                                    }}
                                    label={
                                        <>
                                            <button
                                                className="hyperlink"
                                                type="button"
                                                onClick={() => {
                                                    openModal("agreement", {
                                                        agreement: "pre_info",
                                                        parameter: sale.id,
                                                    });
                                                }}
                                            >
                                                Ön Bilgilendirme Koşulları
                                            </button>{" "}
                                            ve{" "}
                                            <button
                                                className="hyperlink"
                                                type="button"
                                                onClick={() => {
                                                    openModal("agreement", {
                                                        agreement: "distance",
                                                        parameter: sale.id,
                                                    });
                                                }}
                                            >
                                                Mesafeli Satış Sözleşmesi
                                            </button>
                                            ’ni okudum, onaylıyorum.
                                        </>
                                    }
                                />

                                <Btn
                                    type="submit"
                                    disabled={busy || !isAgreementConfirmed}
                                    loading={submitting}
                                    className="aside-cta block primary"
                                >
                                    Alışverişi Tamamla
                                </Btn>
                            </div>
                            <CheckoutSummary summary={sale.summary} />
                        </aside>
                    </>
                )}
            </Form>
        </>
    );
};

const DeliverySlots = () => {
    const { store, busy, selectedSlotId, setSelectedSlotId, touched } =
        useCheckout();

    const { days, initialActiveDay } = useMemo(() => {
        const days = [];
        let initialActiveDay = null;

        for (const day of store.available_times.slice(0, 5)) {
            const processedDay = {
                id: day.date,
                label: formatDate(day.date, "EEEE", { checkRelative: true }),
                dateLabel: formatDate(day.date, "d LLLL"),
                slots: day.times,
            };
            if (processedDay.slots.find((t) => t.id === selectedSlotId)) {
                initialActiveDay = processedDay;
            } else if (
                !initialActiveDay &&
                processedDay.slots.find(
                    (t) =>
                        t.status === 1 && t.hour_total !== t.hourly_order_unit
                )
            ) {
                initialActiveDay = processedDay;
            }

            days.push(processedDay);
        }

        return {
            days,
            initialActiveDay,
        };
    }, [store]);

    const [selectedDay, setSelectedDay] = useState(initialActiveDay ?? days[0]);

    return (
        <div className="main-section checkout-deliveryslots">
            <strong
                className={`section-title${
                    touched && !selectedSlotId ? " error" : ""
                }`}
            >
                Teslimat Zamanı
            </strong>
            <div className="deliveryslots-days">
                <div className="days-innerwrap">
                    {days.map((day) => (
                        <button
                            key={day.id}
                            onClick={() => {
                                setSelectedDay(day);
                            }}
                            disabled={busy}
                            className={`days-day${
                                selectedDay.id === day.id ? " selected" : ""
                            }`}
                            type="button"
                        >
                            <strong className="day-label">{day.label}</strong>
                            <span className="day-date">({day.dateLabel})</span>
                        </button>
                    ))}
                </div>
            </div>

            {selectedDay && (
                <div className="deliveryslots-slots">
                    {selectedDay.slots.map((slot) => {
                        const active = selectedSlotId === slot.id;
                        const past = slot.status !== 1;
                        const full =
                            !past && slot.hour_total === slot.hourly_order_unit;

                        return (
                            <button
                                disabled={busy || past || full}
                                key={slot.id}
                                onClick={() => {
                                    setSelectedSlotId(slot.id);
                                }}
                                className={`slots-slot${
                                    active ? " active" : ""
                                }${touched && !selectedSlotId ? " error" : ""}${
                                    past && !full ? " past" : ""
                                }${full ? " full" : ""}`}
                                type="button"
                            >
                                {full && (
                                    <span className="slot-fulllabel">Dolu</span>
                                )}
                                {slot.hour_range}
                            </button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const Payment = () => {
    const {
        selectedPaymentMethod,
        paymentInfo,
        busy,
        touched,
        setSelectedPaymentMethod,
        masterpass,
    } = useCheckout();
    const { openModal } = useModals();

    return (
        <div className="main-section checkout-payment">
            <strong
                className={`section-title${
                    touched && !paymentInfo ? " error" : ""
                }`}
            >
                Ödeme Yöntemi
            </strong>
            <div className="payment-content">
                <Input
                    inline
                    type="radio"
                    disabled={busy}
                    className="payment-method"
                    name="payment_method"
                    value={selectedPaymentMethod}
                    onChange={setSelectedPaymentMethod}
                    options={paymentMethods}
                />

                {selectedPaymentMethod.value === "doorpayment" ? (
                    <div className="payment-doorpaymentinfo">
                        <Icon name="info" />
                        <p>
                            Kapıda ödeme seçeneğimizde kapıda kredi ve banka kartı ile
                            ödeme yapabilirsiniz, Nakit ödeme geçerli değildir.
                        </p>
                    </div>
                ) : (
                    <div className="payment-masterpass loader-container">
                        <Loader
                            solid
                            message="Ödeme bilgileriniz getiriliyor..."
                            loading={
                                !masterpass.error && !masterpass.initialized
                            }
                        />

                        {masterpass.error ? (
                            <div className="masterpass-info error">
                                Masterpass yüklenirken bir hata ile
                                karşılaşıldı. <br />
                                <button
                                    className="hyperlink"
                                    onClick={() => masterpass.refetch()}
                                >
                                    Buraya
                                </button>{" "}
                                tıklayarak tekrar yüklemeyi deneyebilirsiniz.
                            </div>
                        ) : (
                            <>
                                {masterpass.accountStatus.mode === "locked" ? (
                                    <div className="masterpass-info error">
                                        Masterpass hesabınız bloke edilmiş.
                                        Lütfen bankanızla iletişime geçin.
                                    </div>
                                ) : (
                                    <button
                                        onClick={() => {
                                            openModal("payment");
                                        }}
                                        type="button"
                                        disabled={busy}
                                        className={`masterpass-addbtn${
                                            touched && !paymentInfo
                                                ? " error"
                                                : ""
                                        }${
                                            paymentInfo ? " card-selected" : ""
                                        }`}
                                    >
                                        {paymentInfo ? (
                                            <>
                                                <CardTypeIcon
                                                    className="addbtn-cardtype"
                                                    type={paymentInfo.type}
                                                />
                                                <div className="addbtn-cardtext">
                                                    <strong className="cardtext-title">
                                                        {paymentInfo.name}
                                                    </strong>
                                                    <p className="cardtext-num">
                                                        {paymentInfo.maskedNo}
                                                    </p>
                                                </div>
                                                <span className="addbtn-cardchange">
                                                    değiştir
                                                </span>
                                            </>
                                        ) : (
                                            <div className="addbtn-contentwrap">
                                                <div className="addbtn-content">
                                                    <Icon
                                                        name="card-add"
                                                        className="addbtn-icon"
                                                    />
                                                    <span>
                                                        {masterpass
                                                            .accountStatus
                                                            .hasCards &&
                                                        masterpass.accountStatus
                                                            .isLinked
                                                            ? "Kart seçin."
                                                            : "Kart bilginizi ekleyin."}
                                                    </span>
                                                </div>
                                                {masterpass.accountStatus
                                                    .hasCards && (
                                                    <div className="addbtn-mpinfo">
                                                        <Img
                                                            src={
                                                                image_payment_masterpass
                                                            }
                                                            className="mpinfo-logo"
                                                            alt="Masterpass"
                                                        />
                                                        Masterpass’e kayıtlı
                                                        kartlarınız bulunmakta!
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <Icon
                                            name="angle-right"
                                            className="addbtn-arrow"
                                        />
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const CheckoutOptions = () => {
    const { openModal } = useModals();
    const { sale, setSaleNote, selectedPaymentMethod } = useCheckout();

    const [busy, setBusy] = useState(false);

    const clearNote = () => {
        openModal("confirm", {
            message: "Sipariş notunuzu temizlemek istediğinizden emin misiniz?",
            onConfirm: () => {
                setBusy(true);
                setSaleNote("").finally(() => {
                    setBusy(false);
                });
            },
        });
    };

    return (
        <div className="main-section checkout-options">
            {/* <strong className="section-title">Sipariş Tercihleri</strong> */}
            {/* <span className="section-subtitle">Sipariş verdiğiniz ürünün mağazamızdaki stokta bulunmadığı durum için tercihinizi yapınız.</span> */}
            <div className="options-content">
                <Input
                    className="options-optcheck"
                    disabled={selectedPaymentMethod?.value === "doorpayment"}
                    checked={
                        selectedPaymentMethod?.value === "doorpayment"
                            ? false
                            : undefined
                    }
                    name="non_contact"
                    type="checkbox"
                    uncheckedValue={false}
                    label={
                        <>
                            <strong className="optcheck-title">
                                Temassız Teslimat
                            </strong>
                            <p className="optcheck-description">
                                Ürünleriniz kapınıza bırakılsın.
                            </p>
                        </>
                    }
                />

                <Input
                    className="options-optcheck"
                    name="no_bell"
                    type="checkbox"
                    uncheckedValue={false}
                    label={
                        <>
                            <strong className="optcheck-title">
                                Zile Basma
                            </strong>
                            <p className="optcheck-description">
                                Siparişiniz ulaştığında kuryemiz sizi arasın.
                            </p>
                        </>
                    }
                />

                {!!sale.sale_note && sale.sale_note.length > 0 ? (
                    <div className="options-note">
                        <div className="note-heading">
                            <strong className="heading-title">
                                Sipariş Notu
                            </strong>
                            <div className="heading-controls">
                                <button
                                    disabled={busy}
                                    onClick={() => {
                                        openModal("orderNote");
                                    }}
                                    type="button"
                                    className="controls-btn"
                                >
                                    <Icon name="edit" />
                                </button>
                                <button
                                    disabled={busy}
                                    onClick={clearNote}
                                    type="button"
                                    className="controls-btn"
                                >
                                    <Icon name="trash" />
                                </button>
                            </div>
                        </div>

                        <div className="note-message">{sale.sale_note}</div>
                    </div>
                ) : (
                    <Btn
                        wrapText
                        onClick={() => {
                            openModal("orderNote");
                        }}
                        className="options-notebtn block outline"
                        icon="edit"
                        afterIcon="angle-right"
                    >
                        <span>Sipariş Notu Ekleyin (Opsiyonel)</span>
                    </Btn>
                )}
            </div>
        </div>
    );
};

export default Checkout;
