import PropTypes from 'prop-types';

// Partials
import Pricebox from '/views/partials/pricebox';

const CheckoutSummary = ({ summary }) => {
	if(summary?.price?.original > 0) {
		return (
			<div className="aside-summary">
				<div className="summary-item">
					<strong>Ürünler Toplamı</strong>
					<Pricebox className="item-price" price={summary.price} />
				</div>
	
				{summary.services.map((service, nth) => {
					if(service.service_code !== 'KARGO') {
						return (
							<div className="summary-item" key={nth}>
								<strong>{service.name}</strong>
								<Pricebox className="item-price" price={service.price} />
							</div>
						)
					}
				})}
			</div>
		)
	}
	else {
		return false;
	}
}

CheckoutSummary.propTypes = {
	summary: PropTypes.object,
}

export default CheckoutSummary;